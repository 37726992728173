/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { getRequest } from "./http";
import { WhaleItem } from "./index";

interface RankListRequestParams {
  txCount?: number;
  pageNum?: number;
  orderBy?: string;
  pageSize?: number;
}

interface RankListResponse {
  data: WhaleItem[];
}

// "return_desc" | "return_asc" | "value_desc" | "value_asc";

export default (
  params: RankListRequestParams = {}
): Promise<Array<RankListResponse | undefined>> => {
  const { txCount, pageSize, pageNum = 0, orderBy = "return_desc" } = params;
  const url = `onft/v3/api/whales?${txCount ? `txCount=${txCount}&` : ""}${
    pageSize ? `pageSize=${pageSize}&` : ""
  }pageNum=${pageNum}&orderBy=${orderBy}`;
  return getRequest<RankListResponse>(url);
};
