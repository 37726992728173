
import { defineComponent, watch, onMounted, ref } from "vue";
import imagePlaceholder from "@/assets/imagePlaceholder.svg";

export default defineComponent({
  name: "LazyImage",
  props: {
    src: {
      type: String,
      default: "",
    },
    square: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const imgRef = ref();
    const init = (src: string) => {
      const el = imgRef.value;
      if (!el) return;
      el.setAttribute("data-src", src);
      el.setAttribute("src", imagePlaceholder);
      load(el);
    };

    const imageIsExist = (url = "") => {
      return new Promise((resolve) => {
        let img: HTMLImageElement | undefined = new Image();
        img.onload = function () {
          resolve(true);
          img = undefined;
        };
        img.onerror = function () {
          resolve(false);
          img = undefined;
        };
        img.src = url;
      });
    };

    const load = async (el: HTMLImageElement) => {
      const realSrc = el.dataset.src;
      el.src = ((await imageIsExist(realSrc)) && realSrc) || imagePlaceholder;
      el.removeAttribute("data-src");
    };

    watch(
      () => props.src,
      (value) => {
        init(value);
      }
    );

    onMounted(() => {
      init(props.src);
    });

    return {
      imgRef,
    };
  },
});
