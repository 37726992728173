/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

export const setImgSize = (
  src: string,
  size = "480x480",
  format = "webp"
): string => {
  const reg = /(6chcdn-2.feednews.com\/)/;
  if (reg.test(src)) {
    src = `${src}_${size}?format=${format}`;
  }
  return src;
};
