import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "overflow-hidden"
}
const _hoisted_2 = { class: "pb-full relative bg-customSet-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.square)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "absolute top-0 bottom-0 right-0 left-1/2 h-full transform -translate-x-1/2",
            ref: "imgRef",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
          }, null, 512)
        ])
      ]))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "bg-customSet-1",
        ref: "imgRef",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClick')))
      }, null, 512))
}