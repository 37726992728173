/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { getRequest } from "./http";
import { WhaleItem } from "./index";

interface TrackingListRequestParams {
  pageSize?: number;
  filter?: string;
  maxGroupItems?: number;
  cursorId?: string;
}

interface TrackingListResponse {
  cursorId: number;
  data: WhaleItem[];
}

export default (
  params: TrackingListRequestParams = {}
): Promise<Array<TrackingListResponse | undefined>> => {
  const { cursorId, pageSize = 8, filter = "", maxGroupItems = 12 } = params;

  const url = `onft/v3/api/whale/feed?${
    cursorId ? `cursorId=${cursorId}&` : ""
  }pageSize=${pageSize}&${
    filter ? `filter=${filter}&` : ""
  }maxGroupItems=${maxGroupItems}`;

  return getRequest<TrackingListResponse>(url);
};
