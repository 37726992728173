/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { getRequest } from "./http";
import { NextBlueChipItem } from "./index";

interface NextBlueChipListRequestParams {
  pageNum?: number;
  pageSize?: number;
  orderBy?: string;
}

interface NextBlueChipListResponse {
  data: NextBlueChipItem[];
}

// "bc_owner_rate_desc" | "bc_owner_rate_asc" | "bc_owner_rate_d7_asc" | "bc_owner_rate_d7_desc";

export default (
  params: NextBlueChipListRequestParams = {}
): Promise<Array<NextBlueChipListResponse | undefined>> => {
  const { pageSize, pageNum = 0, orderBy = "bc_owner_rate_desc" } = params;

  const url = `onft/v3/api/n_blue_chips?${
    pageSize ? `pageSize=${pageSize}&` : ""
  }pageNum=${pageNum}&orderBy=${orderBy}`;

  return getRequest<NextBlueChipListResponse>(url);
};
