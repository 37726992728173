/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { getRequest } from "./http";

interface ItemDetailRequestParams {
  contractAddr: string;
  tokenId: string;
  language: string;
  moreInCollection: number;
  country?: string;
}

interface ItemDetailResponse {
  data: ItemDetail;
  code: number;
}

export interface ItemDetail {
  name: string;
  openseaLink: string;
  contract: string;
  tokenId: string;
  curPrice: number;
  lastPrice: number;
  latestTxns: LatestTxn[];
  belongToCollection: {
    id: string;
    name: string;
    banerUrl: string;
    logo: string;
    link: string;
    owners: number;
    totalItems: number;
    floorPrice: number;
    volume: number;
    latestTxns: [
      {
        txHash: string;
        contract: string;
        tokenType: string;
        operator: string;
        nftFrom: string;
        nftTo: string;
        tokenId: string;
        chainNet: string;
        transferTime: string;
        price: number;
        transferType: string;
        relatedItem: string;
        infra_feedback: string;
        txLink: string;
        nftFromLink: string;
        nftToLink: string;
      }
    ];
    tags: string[];
    social: {
      website: string;
      discord: string;
      twitter: string;
      telegram: string;
    };
    contracts: string[];
    items: CollectionItem[];
    d7AvgPrice: number;
    floor1h: number;
    volume1h: number;
    floor24h: number;
    volume24h: number;
    floor7d: number;
    volume7d: number;
    infra_feedback: string;
    volume24hPre: number;
    market: number;
    marketPre: number;
    ownersPre: number;
  };
  changePercent: number;
  imageUrl: string;
  standard: string;
  chain: string;
  avgPrice: number;
  aboveAvg: number;
  d7Trxns: number;
  d7Owners: number;
  deDup: string;
  totalItems: number;
}
export interface LatestTxn {
  txHash: string;
  contract: string;
  tokenType: string;
  operator: string;
  nftFrom: string;
  nftTo: string;
  tokenId: string;
  chainNet: string;
  transferTime: string;
  price: number;
  transferType: string;
  relatedItem: string;
  infra_feedback: string;
  txLink: string;
  nftFromLink: string;
  nftToLink: string;
}

export interface CollectionItem {
  aboveAvg: number;
  avgPrice: number;
  belongToCollection: null;
  chain: string;
  changePercent: number;
  contract: string;
  curPrice: number;
  d7Owners: number;
  d7Trxns: number;
  deDup: string;
  imageUrl: string;
  lastPrice: number;
  latestTxns: [];
  name: string;
  openseaLink: string;
  standard: string;
  tokenId: string;
}

export default (
  params: ItemDetailRequestParams
): Promise<Array<ItemDetailResponse | undefined>> => {
  const {
    contractAddr,
    tokenId,
    language,
    country,
    moreInCollection = 5,
  } = params;

  const url = `onft/v3/api/item?${
    contractAddr ? `contractAddr=${contractAddr}&` : ""
  }${tokenId ? `tokenId=${tokenId}&` : ""}${
    language ? `language=${language}&` : ""
  }${country ? `country=${country}&` : ""}${
    moreInCollection ? `moreInCollection=${moreInCollection}` : ""
  }`;

  return getRequest<ItemDetailResponse>(url);
};
