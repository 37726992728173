
import {
  defineComponent,
  ref,
  watch,
  h,
  VNode,
  PropType,
  onBeforeMount,
} from "vue";
import Loading from "@/components/common/Loading.vue";

export interface Record {
  [key: string]: string | number | undefined;
}

interface ColumnData {
  width: number | string | undefined;
  colCells: ColCell[];
  hasRender: boolean;
  align?: string;
}

interface ColCell {
  innerHTML: unknown;
  hovered: boolean;
  record: Record;
}

interface Pagination {
  pageNo: number;
  pageSize: number;
}

export interface ColumnHeader {
  key: string;
  title: string;
  align?: string;
  width?: string | number;
  sortable?: boolean;
  uppercase?: boolean;
  render?: (h: any, record: Record) => VNode;
  format?: (record: Record) => string | number;
}

export default defineComponent({
  name: "Table",
  components: {
    Loading,
    VNodeRender: {
      props: ["vNode"],
      render() {
        return this.vNode;
      },
    },
  },
  emits: ["click-row", "page-change"],
  props: {
    columns: {
      type: Array as PropType<ColumnHeader[]>,
      default: () => [],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object as PropType<Pagination>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    defaultSort: {
      type: Object,
      default: () => ({
        sortBy: "",
        isAsc: false,
      }),
    },
    rowStyle: {
      type: String,
    },
    isWidget: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const currentPage = ref(0);
    const pageSize = ref(props.pagination?.pageSize || 0);
    const totalPage = ref(0);
    const fullData = ref<Record[]>([]);
    const currentPageData = ref<ColumnData[]>([]);
    const sortStatus = ref(props.defaultSort);
    const hoveredIndex = ref(-1);
    const hasClickRowEvent = ref(false);

    const rowIndex = (index: number) => {
      return index + 1 + currentPage.value * pageSize.value;
    };
    const formatColumnData = (page: number): ColumnData[] => {
      const arr = (
        props.pagination
          ? fullData.value.slice(
              page * pageSize.value,
              (page + 1) * pageSize.value
            )
          : fullData.value
      ).map((item, i) => {
        const index = rowIndex(i);
        return { index, ...item };
      });
      const formatedData = (props.columns as ColumnHeader[]).map((column) => {
        return {
          hasRender: typeof column.render === "function",
          width: column.width,
          colCells: (arr as Record[]).map((item, index) => {
            let innerHTML;
            if (column.render) {
              innerHTML = column.render(h, item);
            } else if (column.format && typeof column.format === "function") {
              innerHTML = column.format(item);
            } else {
              innerHTML = item[column.key];
            }
            return {
              innerHTML,
              hovered: index === hoveredIndex.value,
              record: item,
            };
          }),
          align: column.align,
        };
      });
      return formatedData;
    };

    const pageDecrease = () => {
      if (currentPage.value < 1 || props.loading) return;
      currentPage.value = currentPage.value - 1;
      currentPageData.value = formatColumnData(currentPage.value);
    };

    const pageIncrease = () => {
      if (currentPage.value >= totalPage.value - 1 || props.loading) return;
      currentPage.value = currentPage.value + 1;
      currentPageData.value = formatColumnData(currentPage.value);
    };

    const sortBy = (key: string, sortable?: boolean) => {
      if (!sortable) return;
      if (sortStatus.value.sortBy === key) {
        sortStatus.value.isAsc = !sortStatus.value.isAsc;
      } else {
        sortStatus.value.sortBy = key;
        sortStatus.value.isAsc = false;
      }
      if (sortStatus.value.isAsc) {
        fullData.value.sort((a, b) => {
          if (!a[key]) return 1;
          if (!b[key]) return -1;
          return Number(a[key]) - Number(b[key]);
        });
      } else {
        fullData.value.sort((a, b) => {
          if (!a[key]) return 1;
          if (!b[key]) return -1;
          return Number(b[key]) - Number(a[key]);
        });
      }
      currentPageData.value = formatColumnData(currentPage.value);
    };

    const handleMouseEnter = (index: number) => {
      hoveredIndex.value = index;
    };

    const handleMouseLeave = () => {
      hoveredIndex.value = -1;
    };

    watch(
      () => props.dataSource,
      (value) => {
        currentPage.value = props.pagination?.pageNo || 0;
        totalPage.value = props.pagination
          ? Math.ceil(value.length / props.pagination.pageSize)
          : 0;
        fullData.value = value as Record[];
        currentPageData.value = formatColumnData(currentPage.value);
      },
      {
        immediate: true,
      }
    );

    watch(hoveredIndex, () => {
      currentPageData.value = formatColumnData(currentPage.value);
    });

    watch(currentPage, () => {
      context.emit("page-change");
    });

    onBeforeMount(() => {
      hasClickRowEvent.value = Boolean(context.emit);
    });

    return {
      currentPage,
      pageSize,
      totalPage,
      currentPageData,
      sortStatus,
      hasClickRowEvent,
      pageDecrease,
      pageIncrease,
      sortBy,
      handleMouseEnter,
      handleMouseLeave,
    };
  },
});
